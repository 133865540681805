import React from 'react';
import Head from 'next/head';
import { TabLink as NextNavLink } from '@/ui/shared/components/Next/Link';
import cn from 'classnames';

import GetStartedButton from '@/dataroom/ui/components/Landing/common/GetStartedButton';
import LearnMore from '../LearnMore';
import Support from '@/ui/shared/components/Landing/Sections/Support';
import Features from './Features';
import FooterPattern from '@/ui/shared/components/Landing/Sections/FooterPattern';

import supportStamp from './assets/supportStamp.svg';
import introAnimation from '@/dataroom/ui/components/Landing/investorSet/Home/assets/introAnimation.gif';
import introPattern from '@/dataroom/ui/assets/introPattern.svg';
import supportPattern from './assets/supportPattern.svg';

import styles from '@/dataroom/ui/components/Landing/investorSet/Home/home.scss';
import baseStyles from '../tenantInvestorSet.scss';
import commonHomeStyles from '@/dataroom/ui/components/Landing/common/homeSection.scss';
import spacesStyles from '@/ui/shared/styles/helpers/spaces.scss';
import btnStyles from '@/ui/shared/components/Button/button.scss';

const Home = () => (
  <div className={ styles.homeWrp }>
    <Head>
      <title>Secure Document Library | InvestorSet</title>
      <meta
        name="description"
        content={
          'A secure document library that streamlines investor access to final deal documentation ' +
          'and periodic reporting in fixed income.'
        }
      />
    </Head>
    <div className={ cn(commonHomeStyles.intro, styles.intro) }>
      <div className={ cn(commonHomeStyles.introPattern, styles.introPattern) }>
        <img src={ introPattern } />
      </div>
      <div className={ commonHomeStyles.introContainer }>
        <div className={ cn(commonHomeStyles.introContent, styles.introContent) }>
          <h1 className={ cn(commonHomeStyles.introTitle, baseStyles.title, styles.title) }>
            Access deal documents in minutes, not days
          </h1>
          <div className={ cn(commonHomeStyles.introDescription, baseStyles.description, styles.description) }>
            <p>
              InvestorSet is a document library for corporates to streamline investor access
              to deal documentation and periodic reporting. It’s secure, cost effective and
              backed by fanatical 24x7 support.
            </p>
            <p>InvestorSet is free for qualified investors.</p>
          </div>
          <NextNavLink
            to="/explore-sets"
            className={ cn(btnStyles.action, styles.button) }
            data-test="exploreSets"
          >
            Explore Sets
          </NextNavLink>
          <GetStartedButton
            className={ cn(btnStyles.secondary, baseStyles.secondaryBtn, spacesStyles.mrn, styles.button) }
          />
        </div>
        <div className={ commonHomeStyles.introAnimation }>
          <div className={ cn(commonHomeStyles.introAnimationContainer, styles.introAnimationContainer) }>
            <div className={ styles.introAnimationShadow } />
            <img src={ introAnimation } width={ 608 } height={ 460 } />
          </div>
        </div>
      </div>
    </div>
    <Features />
    <div className={ styles.supportWrp }>
      <Support
        pattern={ supportPattern }
        patternClassName={ styles.supportPattern }
        headingClassName={ styles.supportHeading }
        titleClassName={ styles.supportTitle }
        descriptionClassName={ styles.supportDescription }
        title="Your experience means everything to us"
        description={ (
          <>
            We offer unlimited, 24x7x365 U.S. based phone, email and
            live chat support to you and any user in your deal.
          </>
        ) }
      />
      <div className={ styles.supportStamp }>
        <img src={ supportStamp } />
      </div>
    </div>
    <LearnMore />
    <FooterPattern />
  </div>
);

export default Home;
